import { Component, Input } from '@angular/core';
import { MaintenanceInfo } from '@safarilaw-webapp/shared/app-bootstrap';

@Component({
  selector: 'sl-lpms-maintenance-header',
  templateUrl: './maintenance-header.html',
  styleUrls: ['./maintenance-header.scss'],
  imports: []
})
export class MaintenanceHeaderComponent {
  @Input()
  maintenanceInfo: MaintenanceInfo = undefined;

  //NOTE: We dont' want to use moment here. If we did we'd have to import it and since this is a part of our
  // main bootstrap module that woudl add another 350kb+ causing NG's over limit warning
  // So to avoid moment we use our own simple string formatting
  private _getDayName(day: number) {
    const arr = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return arr[day];
  }
  private _getMonthName(month: number) {
    const arr = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return arr[month];
  }
  private _stringFromDate(date: Date) {
    return (
      this._getDayName(date.getDay()) +
      ', ' +
      this._getMonthName(date.getMonth()) +
      ' ' +
      date.getDate().toString() +
      ', ' +
      date.getFullYear().toString() +
      ' ' +
      date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    );
  }
  get title() {
    return this.maintenanceInfo.header?.title || 'SCHEDULED MAINTENANCE';
  }
  get details() {
    if (this.maintenanceInfo.header?.details) {
      return this.maintenanceInfo.header?.details;
    }
    const fromDate = new Date(this.maintenanceInfo.dateTimeStartUtc);
    const toDate = new Date(this.maintenanceInfo.dateTimeEndUtc);
    const fromString: string = this._stringFromDate(fromDate);
    const toString: string = this._stringFromDate(toDate);
    return `The System will be inaccessible from ${fromString} until ${toString}`;
  }
}
